import { SITE_TYPE_CUSTOMER, LAYOUT_DEFAULT } from './constants';

export const defaultConfig = {
    siteType: SITE_TYPE_CUSTOMER,
    siteId: null,
    offerId: null,
    bazzesco: false,
    bazzescoLocalita: 99, // Provincia di rimini
    customerId: null,
    recipients: null,
    language: 'it',
    layout: LAYOUT_DEFAULT, // [default, compact]
    debug: false,
    phoneRequired: false,
    childrenAgeMax: 16,
    childrenAgeMin: 0,
    gaUserId: true,
    bugsnag: false,
    gaSessionId: null,
    allInclusiveMealPlan: 4,
    maxAdultsPerRoom: 4,
    maxChildrenPerRoom: 4,
    maxRooms: 4,
    maxDays: 30,
    showNewsletterSubscriptionCheck: true,
    showNightsNumber: true,
    showSuccessModal: true,
    resetAfterSubmit: true,
    // showGlobalMessage: false,
    geolocate: false,
    saveUserData: false,
    theme: {},
    customConfig: {},
    dateFormat: 'DD/MM/YYYY',
    onSuccess() {},
    onMount() {},
    onValidationError() {},
    onSubmitError(err) {
        console.error(err); // eslint-disable-line
    },
    onBeforeSubmit() {
        return true;
    }
};

export const defaultTheme = {
    colors: {
        primary: '#7ebd6d',
        primaryTextAlt: '#fff',
        secondary: '#00a3af',
        secondaryTextAlt: '#fff',
        text: '#3e464c',
        error: '#cc0033',
        formBackground: '#fff',
        label: '#4d4d4d',
        labelInput: '#00a3af',
        labelInputActive: '#7ebd6d',
        inputBackground: '#eee',
        inputBorder: '#eee',
        checkboxBorder: '#ccc'
    },
    texts: {}
};
