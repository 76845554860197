import cookie from 'cookie_js';

cookie.expiresMultiplier = 60;

const COOKIE_NAME = 'merida-form-data';

export function getFormDataCookie() {
    if (!cookie.enabled()) {
        return null;
    }

    const cookieData = cookie.get(COOKIE_NAME);

    if (!cookieData) {
        return null;
    }

    const data = JSON.parse(cookieData);

    return data;
}

export function saveFormDataCookie(data, expires) {
    if (!cookie.enabled()) {
        return false;
    }

    let { check_in, check_out, first_name, last_name, email } = data;

    const content = JSON.stringify({
        check_in,
        check_out,
        first_name,
        last_name,
        email
    });

    cookie.set(COOKIE_NAME, content, {
        expires,
        path: '/'
    });
}

export function saveAdriasCookie(data, expires) {
    if (!cookie.enabled()) {
        return false;
    }

    const content = data;

    cookie.set('_aop', content, { expires, path: '/' });
}

export function checkAdriasCookie() {
    return cookie.get('_aop') ? true : false;
}

export function savePixelCookie(data) {
    if (!cookie.enabled()) {
        return false;
    }

    saveAdriasCookie(data, 1051200);

    const formdata = new FormData();
    formdata.append('aop', data);

    const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch('https://pixel.adriasonline.it/px.php', requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}

export function clearFormDataCookie() {
    cookie.remove(COOKIE_NAME);
}
