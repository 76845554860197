import { h, Component } from 'preact';
import { lazy, Suspense } from 'preact/compat';
// import _ from 'lodash';

import { loadConfig } from '../helpers/api';
import { ErrorButton } from './ErrorButton';
// import { injectGlobalStyle } from '../helpers/theme';
// import FormWrapperLoader from './FormWrapper';

const FormWrapperLoader = lazy(() => import(/* webpackChunkName: "merida-form" */ './FormWrapper'));

// TODO: aggiungere testi in altre lingue supportate
const defaultTranslations = {
    it: {
        retry: 'Riprova',
        loading_error: 'Errore durante il caricamento del form'
    },
    en: {
        retry: 'Retry',
        loading_error: 'Error during form loading'
    }
};

function Loader() {
    return (
        <div className="Merida-Loader-Wrapper">
            <div className="Merida-Loader">
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
}

export default class MeridaLoader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            debug: props.debug,
            error: null,
            isLoading: true,
            remoteConfig: {},
            translations: {},
            recipients: this.getInitialRecipients(props)
        };

        props.emitter.on('debug:enabled', () => {
            this.setState({
                debug: true
            });

            console.warn('Merida debug mode enabled!'); // eslint-disable-line
        });

        props.emitter.on('debug:disabled', () => {
            this.setState({
                debug: false
            });

            console.warn('Merida debug mode disabled!'); // eslint-disable-line
        });

        props.emitter.on('debug:status', exchange => {
            exchange.debug = this.state.debug;
        });

        props.emitter.on('get:recipients', exchange => {
            exchange.recipients = this.state.recipients;
        });

        props.emitter.on('add:recipient', id => {
            const index = this.state.recipients.indexOf(id);

            if (index === -1) {
                this.setState({
                    recipients: [...this.state.recipients, id]
                });
            }
        });

        props.emitter.on('remove:recipient', id => {
            const index = this.state.recipients.indexOf(id);

            if (index > -1) {
                this.state.recipients.splice(index, 1);

                this.setState({
                    recipients: [...this.state.recipients]
                });
            }
        });

        props.emitter.on('reset:recipients', () => {
            this.setState({
                recipients: []
            });
        });

        props.emitter.on('set:recipients', ids => {
            this.setState({
                recipients: ids
            });
        });
    }

    getInitialRecipients(props) {
        if (props.config.recipients) {
            return props.config.recipients;
        }

        if (props.config.customerId) {
            return [props.config.customerId];
        }

        // TODO: se non sono specificati dei recipients e nemmeno un customerId (mi trovo quindi in un form interno, presumibilmente un portale)
        // ha senso mostrare un warning, per lo meno in console? Potrebbero sempre essere aggiunti in seguito.
        return [];
    }

    componentDidMount() {
        // injectGlobalStyle(this.props.theme);

        // this.loadTranslations();

        Promise.all([this.fetchRemoteConfig(), this.loadAsyncModules()])
            // this.fetchRemoteConfig()
            .then(() => {
                // TODO: eventualmente prevedere possibilita che le traduzioni possano essere sovrascritte dalla config remota?
                this.setState({
                    isLoading: false
                });
            })
            .catch(err => console.error(err));
    }

    fetchRemoteConfig = () => {
        this.setState({
            isLoading: true,
            error: null
        });

        return loadConfig(this.props.config)
            .then(data => {
                this.setState({
                    remoteConfig: data
                });

                return data;
            })
            .catch(err => {
                console.error(err); // eslint-disable-line

                this.setState({
                    isLoading: false,
                    error: err.message
                });
            });
    };

    loadAsyncModules() {
        // FIXME: se si usa il metodo custom di inserimento tag style posso anche evitare il caricamento asincrono,
        // discorso diverso se si usa emotion in quanto includerebbe il runtime nel chunk iniziale
        return Promise.all([
            import(/* webpackChunkName: "merida-form" */ '../helpers/theme'),
            import(/* webpackChunkName: "i18n/[request]" */ `../i18n/${this.props.config.language}`)
        ])
            .then(([{ injectGlobalStyle }, { translations }]) => {
                injectGlobalStyle({
                    ...this.props.theme,
                    layout: this.props.config.layout,
                    bazzesco: this.props.config.bazzesco
                });

                this.setState({
                    translations
                });

                return translations;
            })
            .catch(err => console.error(err));
    }

    // loadTranslations() {
    //     import(/* webpackChunkName: "i18n/[request]" */ `../i18n/${this.props.config.language}`)
    //         .then(({ translations }) => {
    //             this.setState({
    //                 translations
    //             });
    //         });
    // }

    getTranslation(key) {
        return defaultTranslations[this.props.config.language][key];
    }

    render({ config, theme }, { debug, error, isLoading, remoteConfig, translations, recipients }) {
        if (error && !isLoading) {
            return (
                <div className="Merida-Loader-Wrapper">
                    <div className="icon icon-alert-solid">
                        <span />
                    </div>
                    <p>{this.getTranslation('loading_error')}</p>
                    {debug && <p>{error}</p>}
                    <ErrorButton onClick={this.fetchRemoteConfig}>
                        {this.getTranslation('retry')}
                    </ErrorButton>
                </div>
            );
        }

        if (isLoading) {
            return <Loader />;
        }

        return (
            <Suspense fallback={<Loader />}>
                <FormWrapperLoader
                    config={config}
                    debug={debug}
                    remoteConfig={remoteConfig}
                    theme={theme}
                    translations={translations}
                    recipients={recipients}
                    emitter={this.props.emitter}
                />
            </Suspense>
        );
    }
}
