// import { differenceInDays } from 'date-fns';
import differenceInDays from 'date-fns/difference_in_days';
import _ from 'lodash';

import { SITE_TYPE_CUSTOMER, SITE_TYPE_INTERNAL } from './constants';
// import isEmpty from 'lodash/isEmpty';
// import keys from 'lodash/keys';
// import get from 'lodash/get';
// import set from 'lodash/set';
// import isObject from 'lodash/isObject';

// import yup from 'yup';

// const schema = yup.object().shape({
//   name:      yup.string().required('Prego inserire un nome'),
//   age:       yup.number().required().positive().integer(),
//   email:     yup.string().email(),
//   website:   yup.string().url(),
//   createdOn: yup.date().default(function() {
//     return new Date
//   })
// });

// schema.validate({ age: 'foo' }, { abortEarly: false }, function(err, value){
//   console.warn(err);
// });

function isNumber(value) {
    return /\d+/.test(value);
}

function isEmail(value) {
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test( // eslint-disable-line
        value
    ); // eslint-disable-line
}

export class FormValidator {
    constructor(errorStrings, config) {
        if (!_.isObject(errorStrings) || _.isEmpty(errorStrings)) {
            throw new Error('Please provide a valid errorStrings object');
        }

        this.errorStrings = errorStrings;
        this.config = config;
    }

    validate(values, visited = {}, rooms, force = false) {
        let errors = {};

        this.checkRequired('first_name', values, visited, force, errors);
        this.checkRequired('last_name', values, visited, force, errors);

        if (this.config.phoneRequired === true) {
            this.checkRequired('phone', values, visited, force, errors);
        }

        this.checkDateRange('check_out', values, this.config.maxDays, errors);

        this.checkStartDate('check_in', values, '1970-01-01', errors);
        this.checkStartDate('check_out', values, '1970-01-04', errors);

        // if ((force || visited.name) && _.isEmpty(values.name)) {
        //     // errors.name = _.get(this.errorStrings, 'name');
        //     this.addError(errors, 'name');
        // }

        // if ((force || visited.surname) && _.isEmpty(values.surname)) {
        //     // errors.surname = _.get(this.errorStrings, 'surname');
        //     this.addError(errors, 'surname');
        // }

        if ((force || visited.email) && (_.isEmpty(values.email) || !isEmail(values.email))) {
            this.addError(errors, 'email');
        }

        // if (
        //     (force || visited.email_confirm) &&
        //     (_.isEmpty(values.email_confirm) || values.email !== values.email_confirm)
        // ) {
        //     this.addError(errors, 'email_confirm');
        // }

        if ((force || visited.privacy) && values.privacy !== true) {
            this.addError(errors, 'privacy');
        }

        _.times(rooms, i => {
            const roomKey = `rooms.${i}`;
            const accomodationKey = `${roomKey}.meal_plan`;
            const adultsKey = `${roomKey}.adults`;
            const childrenKey = `${roomKey}.childs`;

            if (
                (force || _.get(visited, accomodationKey)) &&
                _.isEmpty(_.get(values, `${roomKey}.meal_plan`))
            ) {
                errors = _.set(errors, accomodationKey, this.getErrorMessage('meal_plan'));
            }

            if (force || _.get(visited, adultsKey)) {
                const adultValue = _.get(values, adultsKey);
                if (_.isEmpty(adultValue) || !isNumber(adultValue)) {
                    errors = _.set(errors, adultsKey, this.getErrorMessage('adults'));
                }
            }

            if (force || _.get(visited, childrenKey)) {
                const childrenValue = _.get(values, childrenKey);
                if (_.isEmpty(childrenValue) || !isNumber(childrenValue)) {
                    errors = _.set(errors, childrenKey, this.getErrorMessage('childs'));
                } else {
                    _.times(childrenValue, c => {
                        const childrenAgeKey = `${roomKey}.childs_age.${c}`;

                        const childrenAgeValue = _.get(values, childrenAgeKey);

                        if (_.isEmpty(childrenAgeValue) || !isNumber(childrenAgeValue)) {
                            errors = _.set(
                                errors,
                                childrenAgeKey,
                                this.getErrorMessage('childs_age')
                            );
                        }
                    });
                }
            }
        });

        return {
            valid: Object.keys(errors).length === 0,
            errors
        };
    }

    getErrorMessage(key) {
        return _.get(this.errorStrings, key);
    }

    addError(errors, key) {
        errors[key] = this.getErrorMessage(key);
    }

    checkRequired(key, values, visited, force, errors) {
        if ((force || visited[key]) && _.isEmpty(values[key])) {
            this.addError(errors, key);
        }
    }

    checkDateRange(arrivo, values, maxDays, errors) {
        if (maxDays !== 0) {
            let checkIn = values.check_in.split('-');
            let checkOut = values.check_out.split('-');

            checkIn = new Date(checkIn[0], checkIn[1] - 1, checkIn[2]);
            checkOut = new Date(checkOut[0], checkOut[1] - 1, checkOut[2]);

            const diffDays = differenceInDays(checkOut, checkIn);

            if (diffDays > maxDays) {
                this.addError(errors, arrivo);
            }
        }
    }

    checkStartDate(key, values, check, errors) {
        if (values.check_in == check) {
            this.addError(errors, key);
        }
    }
}

export class ConfigValidator {
    constructor() {
        this.errors = [];
    }

    checkIsBoolean(key, value) {
        if (value && typeof value !== 'boolean') {
            this.errors.push({
                key,
                message: `Please enter true or false as value for the key "${key}`
            });
        }
    }

    checkIsNumber(key, value) {
        if (value && !isNumber(value)) {
            this.errors.push({
                key,
                message: `Please enter a number as value for the key "${key}"`
            });
        }
    }

    checkRange(key, value, start, end) {
        if (value && isNumber(value) && (value < start || value > end)) {
            this.errors.push({
                key,
                message: `Please enter a number between ${start} and ${end} for the key "${key}"`
            });
        }
    }

    checkIsFunction(key, fn) {
        if (!_.isFunction(fn)) {
            this.errors.push({
                key,
                message: `"${key}" option should be a function`
            });
        }
    }

    validate(config) {
        this.errors = [];

        if (
            _.isEmpty(config.siteType) ||
            !_.includes([SITE_TYPE_CUSTOMER, SITE_TYPE_INTERNAL], config.siteType)
        ) {
            this.errors.push({
                key: 'siteType',
                message: 'Please set a valid "siteType" value.'
            });
        }

        if (
            config.siteType === SITE_TYPE_CUSTOMER &&
            (_.isUndefined(config.customerId) || !isNumber(config.customerId))
        ) {
            this.errors.push({
                key: 'customerId',
                message: 'Please set a valid "customerId" value.'
            });
        } /*else if (config.siteType === SITE_TYPE_INTERNAL && !_.isNull(config.customerId)) {
            this.errors.push({
                key: 'customerId',
                message: `Cannot set property "customerId" if "siteType" value is not "${SITE_TYPE_CUSTOMER}"`
            });
        }*/

        // if (config.showSuccessModal && _.isNull(config.customerId)) {
        //     this.errors.push({
        //         key: 'customerId',
        //         message: '"customerId" is mandatory when "showSuccessModal" is true'
        //     });
        // }

        if (
            config.siteType === SITE_TYPE_INTERNAL &&
            (_.isUndefined(config.siteId) || !isNumber(config.siteId))
        ) {
            this.errors.push({
                key: 'siteId',
                message: 'Please set a valid "siteId" value.'
            });
        }

        if (_.isEmpty(config.rootEl)) {
            this.errors.push({
                key: 'rootEl',
                message: 'Please set a valid DOM element id as "rootEl" value.'
            });
        }

        this.checkIsBoolean('debug', config.debug);
        this.checkIsBoolean('phoneRequired', config.phoneRequired);
        this.checkIsBoolean('showNightsNumber', config.showNightsNumber);
        this.checkIsBoolean(
            'showNewsletterSubscriptionCheck',
            config.showNewsletterSubscriptionCheck
        );
        this.checkIsBoolean('geolocate', config.geolocate);
        this.checkIsBoolean('showSuccessModal', config.showSuccessModal);

        this.checkIsNumber('maxRooms', config.maxRooms);
        this.checkRange('maxRooms', config.maxRooms, 1, 5);
        this.checkIsNumber('maxAdultsPerRoom', config.maxAdultsPerRoom);
        this.checkRange('maxAdultsPerRoom', config.maxAdultsPerRoom, 1, 4);
        this.checkIsNumber('maxChildrenPerRoom', config.maxChildrenPerRoom);
        this.checkRange('maxChildrenPerRoom', config.maxChildrenPerRoom, 0, 4);

        this.checkIsNumber('saveUserData', config.saveUserData);

        this.checkIsFunction('onSuccess', config.onSuccess);
        this.checkIsFunction('onSubmitError', config.onSubmitError);
        this.checkIsFunction('onValidationError', config.onValidationError);
        this.checkIsFunction('onBeforeSubmit', config.onBeforeSubmit);

        // if (config.showSuccessModal && config.siteType !== SITE_TYPE_CUSTOMER) {
        //     if (process.env.NODE_ENV !== 'test') {
        //         console.warn(`Merida Form: Success modal can not be shown for "${config.siteType}" site`); // eslint-disable-line
        //     }
        // }

        return this.errors.length === 0;
    }

    getErrors() {
        return this.errors;
    }

    getError(key) {
        const keys = this.errors.map(e => e.key);

        const index = keys.indexOf(key);

        if (index === -1) {
            return null;
        }

        return this.errors[index];
    }
}
