export const FORM_NAME = 'merida_form';

export const SITE_TYPE_CUSTOMER = 'customer';
export const SITE_TYPE_INTERNAL = 'internal';

export const MESSAGE_VALIDATION_ERROR = 'validation_error';
export const MESSAGE_SUBMIT_ERROR = 'submit_error';
export const MESSAGE_SUBMIT_SUCCESS = 'submit_success';
export const MESSAGE_EMAIL_ERROR = 'submit_email_error';

export const LAYOUT_DEFAULT = 'default';
export const LAYOUT_COMPACT = 'compact';
