import _ from 'lodash';
import { SITE_TYPE_CUSTOMER, SITE_TYPE_INTERNAL } from './constants';
import md5 from 'md5';
import cookie from 'cookie_js';

// const dummyResponse = {
//     hotel: {
//         id: 686,
//         name: 'Mimosa',
//         category: '6'
//     },
//     meal_plans: [
//         {
//             id: 123,
//             trattamento_id: 6,
//             order: 2,
//             descriptions: {
//                 it: 'Soggiorno Oro',
//                 en: 'Gold Room',
//                 fr: 'Lorem Ipsum',
//                 de: 'Lorem Ipsum',
//                 pl: 'Lorem Ipsum',
//                 ru: 'Lorem Ipsum'
//             }
//         },
//         {
//             id: 456,
//             trattamento_id: 2,
//             order: 1,
//             descriptions: {
//                 it: 'Soggiorno Diamante',
//                 en: 'Diamond Room',
//                 fr: 'Lorem Ipsum',
//                 de: 'Lorem Ipsum',
//                 pl: 'Lorem Ipsum',
//                 ru: 'Lorem Ipsum'
//             }
//         }
//     ]
// };

function checkStatus(res) {
    // console.warn(res);
    if (res.ok) {
        return res;
    } else {
        const error = new Error(res.statusText);
        error.name = 'HTTPError';
        error.response = res;
        throw error;
        // return Promise.reject(error);
    }
}

// function queryParams(params) {
//     return Object.keys(params)
//         .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
//         .join('&');
// }

function makeCall(url, options = {}) {
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(checkStatus)
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(err => {
                // console.warn(err);
                reject(err);
            });
    });
}

function formatConfig(response, language) {
    const { hotel, meal_plans } = response;

    meal_plans.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));

    return {
        customer: hotel,
        meal_plans: meal_plans.map(p => ({
            value: p.id,
            label: p.descriptions[language],
            idTrattamento: p.trattamento_id
        }))
    };
}

function getConfigRequestUrl(config) {
    if (config.siteType === SITE_TYPE_INTERNAL && _.isNull(config.customerId)) {
        return `${process.env.API_ROOT}/v1/mealplans`;
    }

    return `${process.env.API_ROOT}/v1/hotel/${config.customerId}`;
}

function getGenericMealPlan(config) {
    if (config.siteType === SITE_TYPE_INTERNAL && _.isNull(config.customerId)) {
        return true;
    }

    return false;
}

export function loadConfig(localConfig) {
    // let params = _.pick(localConfig, ['language']);

    // _.defaults(params, {
    //     language: 'it'
    // });

    const requestUrl = getConfigRequestUrl(localConfig);

    // const id = localConfig.siteType === SITE_TYPE_CUSTOMER ? localConfig.customerId : 'default';

    return new Promise((resolve, reject) => {
        const xhr = makeCall(requestUrl);

        // resolve(formatConfig(dummyResponse, localConfig.language));
        xhr.then(res => resolve(formatConfig(res, localConfig.language))).catch(err => reject(err));
        // return makeCall(`http://localhost:3007/config/${id}?${queryParams(params)}`);
    });
}

export function gaUserId(email, measurementId) {
    if (typeof ga === 'function' && typeof gtag === 'function') {
        gtag('config', measurementId, {
            user_id: md5(email.toLowerCase())
        });
    } else {
        console.log('Google Analytics non presente');
        // setTimeout(gaUserId, 500);
    }
}

export function sendMessage(formPayload, options) {
    const payload = createMessagePayload(formPayload, options);

    // console.warn(payload);

    // return new Promise(resolve => {
    //     setTimeout(() => {
    //         resolve();
    //     }, 1000);
    // });

    return makeCall(`${process.env.API_ROOT}/v1/email`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
}

export function createMessagePayload(formPayload, options) {
    // FIXME: ripristinare utilizzo variabile debug
    const { debug, localConfig, /*remoteConfig,*/ userCoords, recipients, mrPreno_code } = options;

    // console.warn(remoteConfig, formPayload);
    // const rooms = formatRooms(formPayload.rooms, remoteConfig);
    return {
        ...formPayload,
        // rooms: formatRooms(formPayload.rooms, remoteConfig),
        debug,
        // debug: true,
        hotels: recipients,
        lang: localConfig.language,
        ga_session_id: localConfig.gaSessionId,
        site_id: getSiteId(localConfig),
        offer_id: getOfferId(localConfig),
        // timestamp: Date.now(),
        customConfig: localConfig.customConfig,
        generic_meal_plan: getGenericMealPlan(localConfig),
        ...getEnvData(),
        ...getUtmReferrer(),
        ...getUserData(userCoords),
        ...getFacebookData(),
        mrpreno_code: mrPreno_code
    };
}

// function formatRooms(rooms = [], remoteConfig) {
//     return rooms.map(room => {
//         const accomodation = remoteConfig.accomodations_it.filter(a => a.value == room.meal_plan);

//         if (accomodation && accomodation.length > 0) {
//             room = {
//                 ...room,
//                 meal_plan: accomodation[0].label
//             };
//         }

//         return room;
//     });
// }

function getEnvData() {
    return {
        href: window.location.href,
        // hostname: window.location.origin,
        // pathname: window.location.pathname,
        referrer: document.referrer
    };
}

// function getUtmReferrer() {
//     const utmParams = ['utm_medium', 'utm_campaign', 'utm_content', 'utm_source', 'utm_term'];
//     const utmObj = {};

//     const utmQuery = decodeURIComponent(window.location.search.substring(1));
//     const utmVariables = utmQuery.split('&');

//     let ParameterName = '';
//     let i = '';

//     const getUTMValue = inputParameter => {
//         for (i = 0; i < utmVariables.length; i++) {
//             ParameterName = utmVariables[i].split('=');

//             if (ParameterName[0] === inputParameter) {
//                 return ParameterName[1] === null ? null : ParameterName[1];
//             }
//         }
//     };

//     const valueExists = value => {
//         return value != null && value != '' && value != undefined;
//     };

//     utmParams.forEach(param => {
//         var pValue = getUTMValue(param);

//         if (valueExists(pValue)) {
//             utmObj[param] = pValue;
//         }
//     });

//     console.log(utmObj);

//     return utmObj;
// }
function getFacebookData() {
    const facebookData = {};
    if (!cookie.enabled()) {
        return facebookData;
    }

    const fbp = cookie.get('_fbp');
    const fbc = cookie.get('_fbc');

    if (fbp !== undefined) {
        facebookData['fbp'] = fbp;
    }

    if (fbc !== undefined) {
        facebookData['fbc'] = fbc;
    }

    return facebookData;
}

function getUtmReferrer() {
    const utmParams = ['utm_medium', 'utm_campaign', 'utm_content', 'utm_source', 'utm_term'];
    const utmObj = {};

    // const utmQuery = decodeURIComponent(window.location.search.substring(1));

    // const utmVariables = utmQuery.split('&');

    // utmVariables.forEach(param => {
    //     const splittedParam = param.split('=');
    //     const utmName = splittedParam[0];
    //     const utmValue = splittedParam[1];

    //     if (utmParams.includes(utmName)) {
    //         utmObj[utmName] = utmValue;
    //     }
    // });

    //utilizzo del local storage
    let utmLocalStorage = localStorage.getItem('ao_utm');

    if (utmLocalStorage !== '' && utmLocalStorage !== null) {
        utmLocalStorage = JSON.parse(utmLocalStorage);
        Object.entries(utmLocalStorage).forEach(entry => {
            const [key, value] = entry;
            Object.entries(value).forEach(index => {
                const [name, text] = index;
                const localUtmName = name;
                const localUtmValue = text;

                if (utmParams.includes(localUtmName)) {
                    utmObj[localUtmName] = localUtmValue;
                }
            });
        });
    }
    return utmObj;
}

function getUserData(userCoords) {
    return {
        // language: navigator.language,
        user_agent: navigator.userAgent,
        user_coords: userCoords,
        platform: navigator.platform
    };
}

function getSiteId(config) {
    if (config.siteType === SITE_TYPE_CUSTOMER) {
        return 3;
    }

    return config.siteId;
}

function getOfferId(config) {
    return config.offerId;
}

// {
//     "ga_session_id": "adasdjasidjaisdjiasjdiasjdias", OK
//     "email": "rossi.mario@test.it", OK
//     "first_name": "Rossi", OK
//     "last_name": "Mario", OK
//     "city": "Rimini", SKIP
//     "address": "Via Asmara, 1", SKIP
//     "phone": "3490625231", OK
//     "lang": "it", OK
//     "newsletter": true, OK
//     "hotels": [ OK
//         673
//     ],
//     "site_id": 3, OK
//     "notes": "un preventivo per 2 persone e un bimbo", OK
//     "rooms": [ OK
//         {
//             "room_type": "singola vista mare", ???
//             "meal_plan": "All Inclusive", OK
//             "adults": 2, OK
//             "childs": 1, OK
//             "childs_age": [ OK
//                 3
//             ]
//         }
//     ],
//     "check_in": "2019-07-07", OK
//     "check_out": "2019-07-14", OK
//     "referrer": "http://www.entrainhotel.com/offerte-speciali.php?nome=offerte-ponte-del-2-giugno-abruzzo", OK
//     "debug": true, OK
//     "href": "https://www.entrainhotel.com/contattaci.php", OK
//     "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) Chrome/73.0.3683.103", OK
//     "user_coords": { OK
//         "latitude": 44.0560946,
//         "longitude": 12.5642373
//     },
//     "platform": "Win32", OK
//     "privacy": true OK
// }
